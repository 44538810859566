// import { RequestsService } from "./requests.service";
import { HttpHeaders } from "@angular/common/http";

export class Headers {


    /**
     * Defaults
     */
    private headers:any = {
        'Accept': 'application/json',
    };

    constructor() {}

    public get()
    {
        return new HttpHeaders( this.headers );
    }

    public set(index:any, value?:any) : Headers
    {
        if ( 'object' == typeof index ) {
            this.headers = Object.assign( this.headers, index );
            return this;
        }
        
        this.headers[index] = value;

        return this;
    }

    public remove(index:string)
    {
        delete this.headers[index];
    }
}
