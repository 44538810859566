import { Component, ElementRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CardManualEntry } from './helpers/card-manual-entry';
import { AuthService } from './services/auth.service';
import { EventsService } from './services/events.service';
import { InnocardService } from './services/innocard.service';
import { RequestsService } from './services/requests/requests.service';
import { StorageService } from './services/storage.service';
import { TokenService } from './services/token.service';
import { MenuService } from './services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages:any = [];
  
  public labels:any = {};

  public account:any = {};
  public account_loading:any = false;

  constructor(
    public router : Router,
    public translate: TranslateService,
    public authService: AuthService,
    public requestService: RequestsService,
    public tokenService: TokenService ,
    public eventsService: EventsService,
    private menuController: MenuController,
    private storageService: StorageService,
    private innocardService: InnocardService,
    private cardManualEntry:CardManualEntry,
    private menuService: MenuService,
    private el: ElementRef
  ) {

    /**
     * App default language
     */
    this.translate.setDefaultLang('de'); 

    /**
     * User defined language
     */
    let locale;
    if (locale = this.storageService.get('Locale')) {
      this.translate.use(locale);
    } else if (locale = this.storageService.get('LanguageCode')) {
      this.translate.use(locale);
    }

  }

  generateMenu()
  {
    let user = this.storageService.get('user');

    if ( user ) {
      this.menuService.get(
        user.RoleType
      ).then((s:any) => this.appPages = s )
    }
  }
  
  ngOnInit()
  {
    this.translate.get('card')
    .subscribe(success => {
      this.labels['card'] = success;
    });

    /**
     * Account
     */
    let account = this.storageService.get('account');
    if ( account && account.MyAccount ) {
      this.account = account;
      
      this.generateMenu();
    }

    this.generateMenu();

    /**
     * Try to renew session if stored
     */
    // this.renewAuth(); // disabled because a problem 

    /**
     * Enable / Disable side menu
     */
    this.router.events.subscribe( ( event:any ) => {
      
      if (!event) return;
      if (event instanceof NavigationStart) {
        
        // enable or disable side menu
        if (/\/(login|reset-password|reset-link|register)\b/.test(event.url)) {
          return this.menuController.enable(false);
        }
        return this.menuController.enable(true);
      }
    })

    this.menuController.enable(false);

    /**
     * On login, set user language
     */
    this.eventsService.subscribe( 'user:signin', (event:any) => {
      
      // todo: check necessity of load this copntent
      // this.innocardService.updateAdditionalData();
      this.account = this.storageService.get('account');
      if ( null == this.storageService.get('Locale') ) {
        /**
         * Only if user has not language defined by locale button
         */
        if ( event.LanguageCode ) {
          this.storageService.set('LanguageCode', event.LanguageCode.toLowerCase());
          this.translate.use(
            event.LanguageCode.toLowerCase(),
          )
        }
      }

      this.generateMenu();
    });


    if ( !this.account ) {
      this.account_loading = true;
    }
    this.eventsService.subscribe( 'account:loaded', () => {
      this.account = this.storageService.get('account');
      this.account_loading = false;
    });

    /**
     * When any logout event published
     */
    this.eventsService.subscribe('user:sessionexpired', async () => {
      this.renewAuth();
    });

    /**
     * random request to check if user is logged in
     */
    this.innocardService.getContracts();
  }

  async renewAuth(location:any = '') {
    
    // user logged in 
    let credentials = this.authService.getStoredCredentials();
    
    if ( credentials ) {
      try {
        let response:any = await this.innocardService.login( credentials.username , credentials.password );

        this.account = this.storageService.get('account');
        location.reload();
        
        return;
      } catch (e) {
        return this.logout();        
      }
    } else {
      return this.logout();
    }

  }

  async manualEntry()
  {
    this.cardManualEntry.request();
  }

  logout()
  {
    this.innocardService.logout();
    this.account = null;
    return this.router.navigate(['/login']);
  }
}
