export class UrlHandler {

    private urlString: any = null;

    public setUrl(url:string) 
    {
        this.urlString = url;

        return this;
    }

    public buildUrlQueryString(path:string, args?:any): string {
        
        if ( /^https?:\/\//.test(path) ) return path;

        return this.urlString + path + ( args ? '?' + this.serialize( args ).join( '&' ) : '' )
    }

    private serialize(params:any, parent:any = null) {

        let esc = encodeURIComponent;
        let query = [];

        for (let i in params) {
            let element = params[i];
            if ('object' == typeof element) {
                let qr = this.serialize(element, (parent ? parent + `[${esc(i)}]` : esc(i) ) );

                Object.keys(qr).map( (el:any) => {
                query.push(qr[el]);
                })
            } else {
                parent ? query.push(`${parent}[${esc(i)}]=${esc(element)}`) : query.push(`${esc(i)}=${esc(element)}`);
            }
        }

        return query;
    }

}
