import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    public storage:StorageService,
  ) { }

  public get() : any
  {
    return {
      'UserSessionID': this.storage.get('UserSessionID'),
      'SessionIDHash': this.storage.get('SessionIDHash')
    };
  }

  public set(UserSessionID : any, password : any) : TokenService
  {
    this.storage.set('UserSessionID', UserSessionID)
    this.storage.set('SessionIDHash', require('js-sha1') ( UserSessionID + password ) );

    return this;
  }

  public remove() : TokenService
  {
    this.storage.remove('UserSessionID');
    this.storage.remove('SessionIDHash');

    return this;
  }
}
