import { RequestsService } from "./requests.service";
import { RequestsServiceV2 } from "./requests.v2.service";

export class Config {

    private config:any = {
        baseUrl: null
    };

    constructor( 
        private root:RequestsService|RequestsServiceV2 
    ) {}

    public set( index:any, value?:string )
    {
        if ( 'object' == typeof index ) {
            this.config = Object.assign( this.config, index );
            return this;
        }
        
        this.config[index] = value;
        return this;
    }

    public get()
    {
        return this.config;
    }

    public setBaseUrl(url:string) : Config {

        try {
            new URL(url)
        } catch (e) {
            throw new Error(`URL (${url}) provided is not a valid URL.`);
        }
        
        if ( '/' != url.slice(-1) && !url.includes('?') ) {
            url += '/'
        }

        this.set( 'baseUrl', url );

        return this;
    }

    public getBaseUrl() : string
    {
        return this.config.baseUrl;
    }
}
