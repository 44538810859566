import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public get(key:string) {
    let item = localStorage.getItem(key);

    if ( null == item ) return null;

    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  public set(key:string, obj:any) 
  {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  public remove(key:string)
  {
    localStorage.removeItem(key);
  }
}
