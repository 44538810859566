import { Injectable } from '@angular/core';
import { RequestsService } from './requests/requests.service';
import { environment } from '../../environments/environment';
import { EventsService } from './events.service';
import { TokenService } from './token.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { RequestsServiceV2 } from './requests/requests.v2.service';

@Injectable({
  providedIn: 'root'
})
export class InnocardService {

  constructor(
    private requestService: RequestsService,
    private requestServiceV2: RequestsServiceV2,
    private eventsService: EventsService,
    private tokenService: TokenService,
    private translate: TranslateService,
    private authService: AuthService,
    private storageService: StorageService ) {

    this.requestService.config.setBaseUrl(environment.innocard.api);
    this.requestServiceV2.config.setBaseUrl(environment.innocard.api_v2);

    /**
     * If user authenticated, configure API to send tokens in all requests
     */
    if ( this.tokenService.get() ) {

      this.requestService.interceptors.beforeRequest((request:any) => {
        request.arguments = { 
          ...this.tokenService.get(), 
        };

        if (this.translate.currentLang) {
          request.arguments.LanguageCode = this.translate.currentLang.toUpperCase()
        }
      })

      /**
       * API V2
       * If user authenticated, configure API to send tokens in all requests
       */
      this.requestServiceV2.interceptors.beforeRequest((request:any) => {
        request.arguments = { 
          ...this.tokenService.get(), 
        };

        if (this.translate.currentLang) {
          request.arguments.LanguageCode = this.translate.currentLang.toUpperCase()
        }
      });

    }
    
    /**
     * Check session expired API response
     */
    this.requestService.interceptors.onSuccess((data:any) => {
      let index:any = Object.keys(data);

      if ( index.length != 1 ) return;

      let response = data[index[0]];

      if ( response.MessageCategory == 'E' && response.StatusText.indexOf('User Session' ) >= 0 ) {
        this.eventsService.publish('user:sessionexpired', {} );
      }
    })

    /**
     * API v2
     * Check session expired API response
     */
    this.requestServiceV2.interceptors.onSuccess((data:any) => {
      let index:any = Object.keys(data);

      if ( index.length != 1 ) return;
      let response = data[index[0]];

      let regex = /session\ expired|invalid\ session/ig;
      if ( response.MessageCategory == 'E' && ( regex.test(response.StatusText) || regex.test(response.UserMessage) ) ) {
        this.eventsService.publish('user:sessionexpired', {} );
      }
    })
  }

  public login(username:any, password:any, staySigned:boolean = false) {
    return new Promise((resolve, reject) => {

      let auth = this.authService.login(username, password, staySigned);

      auth.then(async success => {
        let account = await this.getAccount();
        this.storageService.set('account', account.MyAccountSelect.MyAccounts[0]);
        this.eventsService.publish('account:loaded', account.MyAccountSelect.MyAccounts[0] );

        // this.storageService.set('Locale', account.MyAccountSelect.MyAccounts[0]);

        this.updateAdditionalData();
        resolve(success);

      }).catch(err => reject(err));
      return auth;
      
    })
  }

  public async logout() {
    await this.authService.logout();

    this.eventsService.publish('user:signout', {} );

    this.storageService.remove('user');
    this.storageService.remove('account');
    this.storageService.remove('customer_cards');
    this.storageService.remove('customer_home_coupons');
    this.storageService.remove('contracts');
    this.storageService.remove('terminals');
    this.storageService.remove('locations');
    this.storageService.remove('LanguageCode');
    this.storageService.remove('Locale');

    this.translate.use('de');
  }

  public async updateAdditionalData() : Promise<any>
  {
    // this.getContracts().then(success => {
    //   this.storageService.set('contracts', success.LOINContractSelect);
    // });

    // this.getTerminals().then(success => {
    //   this.storageService.set('terminals', success.LOINTerminalSelect);
    // });

    // this.getLocations().then(success => {
    //   this.storageService.set('locations', success.LOINLocationSelect);
    // });
    // return new Promise( async (resolve, reject) => {
    //   try {
    //     this.storageService.set('contracts', (await this.getContracts()).LOINContractSelect);
    //     this.storageService.set('terminals', (await this.getTerminals()).LOINTerminalSelect);
    //     this.storageService.set('locations', (await this.getLocations()).LOINLocationSelect);
      
    //     resolve({
    //       contracts:  this.storageService.get('contracts'),
    //       terminals:  this.storageService.get('terminals'),
    //       locations:  this.storageService.get('locations'),
    //     })
    //   } catch (e) {
    //     reject(e);
    //   }

    // });
  }

  public encodeQR(string:any)
  {
    return this.requestServiceV2.get(`EncodeQR/${string}`);
  }

  public decodeQR(string:any)
  {
    return this.requestServiceV2.get(`DecodeQR/${string}`);
  }

  public hashInfo(hash:any)
  {
    return this.requestServiceV2.get(`HashInfo`, { hash, token: '6B0290E90170048D421C7606AC34FDB56873D7B05E855551C252A897EB9CB45D' });
  }

  public getCard(card_number:string)
  {
    return this.requestServiceV2.get(`Card`, {
      'Card-Id' : card_number
    });
  }

  public checkCard(params:any)
  {
    return this.requestServiceV2.get(`CheckCard`, {
      'Card-ID' : params.CardID,
      'PIN': params.PIN
    });
  }

  public getCards(params:any = {})
  {
    return this.requestServiceV2.get(`Card`, params);
  }

  public getNonRegisteredCards(params:any = {})
  {
    params['RegisteredFlag'] = 0;
    return this.requestServiceV2.get(`Card`, params);
  }

  public getCardByCustomer(customer:string)
  {
    return this.requestServiceV2.get(`Card`, {
      'CustomerID' : customer
    });
  }

  public getCardCoupons(args:any)
  {
    return this.requestServiceV2.get(`CardCoupon`, args );
  }

  public getCountry(args:any)
  {
    return this.requestServiceV2.get('Country/all', args );
  }


  public getTransactions(args:any = {})
  {
    return this.requestServiceV2.get(`Transaction`, args);
  }

  public getTransactionTypes(args:any = {})
  {
    return this.requestServiceV2.get(`TransType`, args);
  }

  public putTransactionReceipt(args = { TransactionID: '', ReceiptTo: '' })
  {
    return this.requestServiceV2.put(`TransactionReceipt`, args);
  }

  public getAccount(args:any = {})
  {
    return this.requestServiceV2.get(`MyAccount`, args);
  }

  public getCustomers(args:any = {})
  {
    return this.requestServiceV2.get(`Customer`, args);
  }

  public generateResetLink(Username:any)
  {
    // todo: need to implement API v2
    return this.requestServiceV2.put(`ResetLink`, { Username });
  }

  public resetPassword(params:any = { username: '', password: '', identifier: '' })
  {
    // todo: need to implement API v2
    return this.requestServiceV2.put(`UserPw`, { Username: params.username, Password: params.password, Identifier: params.identifier });
  }

  public putAccount(args:any = {})
  {
    return this.requestServiceV2.put(`MyAccount`, args);
  }

  public putCustomer(args:any = {})
  {
    return this.requestServiceV2.put(`Customer`, args); 
  }

  public customerRegister(args:any = {})
  {
    return this.requestServiceV2.put(`CustomerRegister`, args); 
  }
  
  public putCard(args:any = {})
  {
    return this.requestServiceV2.put(`Card`, args); 
  }

  public getContracts(args:any = {})
  {
    return this.requestServiceV2.get(`Contract`, args);
  }

  public putContracts(args:any = {})
  {
    return this.requestServiceV2.put(`Contract`, args);
  }

  public getContractTypes(args:any = {})
  {
    return this.requestServiceV2.get(`ContractTypes`, args);
  }

  public getCoupon(CouponID:any)
  {
    return this.requestServiceV2.get(`Coupon`, { CouponID });
  }

  public getCoupons(args:any)
  {
    return this.requestServiceV2.get(`Coupon`, args);
  }

  public getCouponTypes(args:any = {})
  {
    return this.requestServiceV2.get(`CouponTypes`, args);
  }

  public putCoupon(args:any)
  {
    return this.requestServiceV2.put(`Coupon`, args );
  }

  public getTerminals(args:any = {})
  {
    return this.requestServiceV2.get(`Terminal`, args);
  }

  public putTerminal(args:any = {})
  {
    return this.requestServiceV2.put(`Terminal`, args);
  }

  public getLocations(args:any = {})
  {
    return this.requestServiceV2.get(`Location`, args);
  }

  public getStoredContracts()
  {
    return this.storageService.get('contracts');
  }

  public getStoredTerminals()
  {
    return this.storageService.get('terminals');
  }

  public getTransactionsReport(args:any = {})
  {
    return this.requestServiceV2.get(`TransactionReport`, args);
  }

  public getTransaction(args:any = {})
  {
    return this.requestServiceV2.get(`Transaction`, args);
  }

  public putTransaction(args:any = {})
  {
    return this.requestServiceV2.put(`Transaction`, args);
  }

  public getRights(args:any = {})
  {
    return this.requestServiceV2.get(`UserViews`, args);
  }

  public getCampaigns(args:any = {})
  {
    return this.requestServiceV2.get(`Campaign`, args);
  }

  public getCampaignTypes(args:any = {})
  {
    return this.requestServiceV2.get(`CampaignTypes`, args);
  }

  public getCampaignContentTypes(args:any = {})
  {
    return this.requestServiceV2.get(`CampaignContentType`, args);
  }

  public putCampaign(args:any = {})
  {
    return this.requestServiceV2.put(`Campaign`, args);
  }

  public getTennants(args:any = {})
  {
    return this.requestServiceV2.get(`Tennant`, args);
  }

  public putTennants(args:any = {}, formData:any = {})
  {
    return this.requestServiceV2.post(`Tennant`, args, formData);
  }
  
  public getMerchants(args:any = {})
  {
    return this.requestServiceV2.get(`Location`, args);
  }

  public putMerchants(args:any = {}, formData:any = {})
  {
    return this.requestServiceV2.post(`Merchant`, args, formData);
  }

  public getBonus(args:any = {})
  {
    return this.requestServiceV2.get(`Bonus`, args);
  }

  public getBonusTypes(args:any = {})
  {
    return this.requestServiceV2.get(`BonusTypes`, args);
  }

  public getBonusBases(args:any = {})
  {
    return this.requestServiceV2.get(`BonusBase`, args);
  }

  public putBonus(args:any = {})
  {
    return this.requestServiceV2.put(`Bonus`, args);
  }

  public getDistributions(args:any = {})
  {
    return this.requestServiceV2.get(`Distribution`, args);
  }

  public putDistributions(args:any = {})
  {
    return this.requestServiceV2.put(`Distribution`, args);
  }

  public getDistributionCriteria(args:any = {})
  {
    return this.requestServiceV2.get(`DistributionCriteria`, args);
  }

  public getDistributionStatus(args:any = {})
  {
    return this.requestServiceV2.get(`DistributionStatus`, args);
  }

  public getDistributionTypes(args:any = {})
  {
    return this.requestServiceV2.get(`DistributionType`, args);
  }

  public getMessageTypes(args:any = {})
  {
    return this.requestServiceV2.get(`MessageTypes`, args);
  }

  public getPushMsgTypes(args:any = {})
  {
    return this.requestServiceV2.get(`PushMsgTypes`, args);
  }

  public getMessages(args:any = {})
  {
    return this.requestServiceV2.get(`Message`, args);
  }

  public putMessage(args:any = {}, body:any = {})
  {
    return this.requestServiceV2.put(`Message`, args, body );
  }

  public getUsers(args:any = {})
  {
    return this.requestServiceV2.get(`User`, args);
  }

  public putUser(args:any = {})
  {
    return this.requestServiceV2.put(`User`, args);
  }

  public getRoleTypes(): Promise<any>
  {
    const data:any = {
      StatusText: 'Success',
      LOINRoleTypeSelect: {
        RoleTypes: [
          { "RoleType": {"RoleID": 1, "RoleType": "TE", "RoleDescription": "Tennant"}},
          { "RoleType": {"RoleID": 2, "RoleType": "ME", "RoleDescription": "Merchant without customer"}},
          { "RoleType": {"RoleID": 3, "RoleType": "CU", "RoleDescription": "Customer"}},
          { "RoleType": {"RoleID": 4, "RoleType": "TE", "RoleDescription": "Tennant-Read"}},
          { "RoleType": {"RoleID": 5, "RoleType": "SU", "RoleDescription": "Tennant-Super"}},
          { "RoleType": {"RoleID": 6, "RoleType": "ME", "RoleDescription": "Merchant with Customer"}},
          { "RoleType": {"RoleID": 7, "RoleType": "SU", "RoleDescription": "Superuser-Operation"}},
          { "RoleType": {"RoleID": 8, "RoleType": "TE", "RoleDescription": "Tennant-Norights-Readonly"}},
        ]
      }
    };

    return new Promise((resolve) => {
      resolve(data)
    })
  }

  public putReversal(args:any = {})
  {
    return this.requestServiceV2.put('Reversal', args);
  }

  public putGenerateCards(args:any = {})
  {
    return this.requestServiceV2.put('GenerateCards', args);
  }

  public getClearing(args:any = {})
  {
    return this.requestServiceV2.get('Clearing', args);
  }

  public getCardStatusSchedules(args:any = {})
  {
    return this.requestServiceV2.get('CardStatusSchedule', args);
  }

  public putCardStatusSchedule(args:any = {})
  {
    return this.requestServiceV2.put('CardStatusSchedule', args);
  }

  public getCardStatus(params:any = {})
  {
    return this.requestServiceV2.get('CardStatus', params);
  }

  public generateTransactionsExport(params:any = {})
  {
    return this.requestServiceV2.get('TransactionsExport', params);
  }

  public putMassLoad(params:any)
  {
    return this.requestServiceV2.put('MassLoad', params);
  }

  public getInvoiceBillingTypes()
  {
    return this.requestServiceV2.get('InvoiceBillingTypes');
  }

  public getReportsQueue(args:any = {})
  {
    return this.requestServiceV2.get('ReportsQueue', args);
  }

  public putReportsQueue(args:any = {})
  {
    return this.requestServiceV2.put('ReportsQueue', args);
  }

  public getQueuesMassTransactions(args:any = {})
  {
    return this.requestServiceV2.get('MassTransactions', args);
  }

  public putQueuesMassTransactions(args:any = {})
  {
    return this.requestServiceV2.put('MassTransactions', args);
  }
}
