import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { addIcons } from 'ionicons';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {

  Sentry.init({
    dsn: "https://68662351699304cce704080ac9dec6e8@o4504414737006592.ingest.sentry.io/4505717335982080",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:beta.innocard.varlik.com.br", "https:app.innocard.varlik.com.br"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

addIcons({
  'csv': 'assets/icon/csv_icon.svg',
  'pdf': 'assets/icon/PDF_icon.svg'
});