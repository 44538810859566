<ion-app>
  <ion-split-pane contentId="main-content">
    
    <ion-menu contentId="main-content" type="overlay">
<!-- 
      <ion-header>
        <ion-toolbar color="secondary" fill="clear">

          <ion-title>Innocard Loyalty</ion-title>
      
        </ion-toolbar>  
      
      </ion-header> -->
      <ion-content>
        <ion-list id="inbox-list">
          <ion-item *ngIf="account_loading">
            <div class="ion-text-center">
              <ion-spinner name="dots"></ion-spinner>
            </div>
          </ion-item>
          <ion-item detail="false" lines="none" *ngIf="account && account.MyAccount">
            <ion-avatar slot="start">
              <img src="assets/img/logo/logo_small.svg" alt="">
            </ion-avatar>
            <ion-label>
              <h2>{{ account.MyAccount.Name || 'user' | translate }}</h2>
              <p>{{ account.MyAccount.UserName }}</p>
            </ion-label>
          </ion-item>
<!-- 
          <ion-list-header>
            <div class="logo">
              <img src="assets/img/logo.svg">
            </div>
          </ion-list-header>
           -->
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item *ngIf="!p.handler" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="router.isActive(p.url, true)">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title | translate }}</ion-label>
            </ion-item>

            <ion-item *ngIf="p.handler" button lines="none" detail="false" (click)="p.handler()">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title | translate }}</ion-label>
            </ion-item>

          </ion-menu-toggle>
        </ion-list>

        <!-- <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list> -->

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>
