import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterPaginationComponent } from './footer-pagination.component';

@NgModule({
  declarations: [
    FooterPaginationComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [
    FooterPaginationComponent,
  ]
})
export class FooterPaginationComponentModule { }