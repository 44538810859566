import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
  declarations: [
    LanguageSelectorComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [
    LanguageSelectorComponent,
  ]
})
export class LanguageSelectorComponentModule { }