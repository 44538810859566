import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LanguageSelectorComponentModule } from '../language-selector/language-selector.module';
import { TopbarComponent } from './topbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    TopbarComponent
  ],
  imports: [
    LanguageSelectorComponentModule,
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  exports: [
    TopbarComponent
  ]
})
export class TopbarComponentModule { }