import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from './config';
import { Headers } from './headers';
import { Interceptors } from './interceptors';
import { UrlHandler } from './url-handler';

@Injectable({
  providedIn: 'root'
})
export class RequestsServiceV2 {

  public config:Config;
  
  public interceptors:Interceptors;

  public headers:Headers;

  public arguments:any = {};

  private urlHandler:UrlHandler;

  constructor(
    private http: HttpClient
  ) {  

    this.config = new Config( this );
    this.interceptors = new Interceptors( this );
    this.headers = new Headers();
    this.urlHandler = new UrlHandler();
  }

  public get(path:string, params?:any) : Promise<any>
  {
    this.interceptors.get().beforeRequest.call(this, this );

    let url = this.urlHandler
    .setUrl(
      this.config.getBaseUrl()
    )
    .buildUrlQueryString( path, { ...this.arguments, ...params });

    let promise = new Promise((resolve, reject) => {
      this.http.get( url , {
        headers: this.headers.get()
      })
      .subscribe({
        next: (value) => {
          this.interceptors.get().onSuccess.call({}, value)
          resolve(value);
        },
        error: (err) => {
          this.interceptors.get().onFailure.call({}, err)
          reject(err);
        },
        complete: this.interceptors.get().onComplete
      })
    });

    return promise;
  }

  public put(path:string, params?:any, body:any = null) : Promise<any>
  {
    this.interceptors.get().beforeRequest.call(this, this );
    
    let url = this.urlHandler
    .setUrl(
      this.config.getBaseUrl()
    )
    .buildUrlQueryString( path, { ...this.arguments, ...params });

    let promise = new Promise((resolve, reject) => {
      this.http.put(url, body, {
        headers: this.headers.get(),
      })
      .subscribe({
        next: (value) => {
          this.interceptors.get().onSuccess.call({}, value)
          resolve(value);
        },
        error: (err) => {
          this.interceptors.get().onFailure.call({}, err)
          reject(err);
        },
        complete: this.interceptors.get().onComplete
      })
    });

    return promise;
  }

  public post(path:string, params?:any, body:any = null) : Promise<any>
  {
    this.interceptors.get().beforeRequest.call(this, this );
    
    let url = this.urlHandler
    .setUrl(
      this.config.getBaseUrl()
    )
    .buildUrlQueryString( path, { ...this.arguments, ...params });

    let promise = new Promise((resolve, reject) => {
      this.http.post(url, body, {
        headers: this.headers.get(),
      })
      .subscribe({
        next: (value) => {
          this.interceptors.get().onSuccess.call({}, value)
          resolve(value);
        },
        error: (err) => {
          this.interceptors.get().onFailure.call({}, err)
          reject(err);
        },
        complete: this.interceptors.get().onComplete
      })
    });

    return promise;
  }

}
