import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageSelectorComponentModule } from './components/language-selector/language-selector.module';
import { CardManualEntryModule } from './helpers/card-manual-entry';
import { TopbarComponentModule } from './components/topbar/topbar.module';
import { FooterPaginationComponentModule } from './components/footer-pagination/footer-pagination.module';
import * as Sentry from "@sentry/angular-ivy";
import { SelectSearchableComponentModule } from './components/select-searchable/select-searchable.component.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({
      mode: 'ios'
    }), 
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: ((http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/lang/', '.json');
        }),
        deps: [ HttpClient ]
      }
    }),
    
    /** Custom components */
    CardManualEntryModule,
    FooterPaginationComponentModule,
    LanguageSelectorComponentModule,
    TopbarComponentModule,
    SelectSearchableComponentModule
  ],
  providers: [{ 
    provide: RouteReuseStrategy, 
    useClass: IonicRouteStrategy 
  },{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [ Router ],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
