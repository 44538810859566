import { RequestsService } from "./requests.service";
import { RequestsServiceV2 } from "./requests.v2.service";

export class Interceptors {

    private calls:any = {
        onSuccess: (...params:any) => {},
        onFailure: (...params:any) => {},
        onFinish: (...params:any) => {},
        beforeRequest: (...params:any) => {},
    }

    constructor(
        private root : RequestsService|RequestsServiceV2
    ) {}

    public get()
    {
        return this.calls;
    }

    public onSuccess(callable:any):Interceptors
    {
        this.calls.onSuccess = callable;

        return this;
    }

    public onFailure(callable:any):Interceptors
    {
        this.calls.onFailure = callable;

        return this;
    }

    public onFinish(callable:any):Interceptors
    {
        this.calls.onFinish = callable;

        return this;
    }

    public beforeRequest(callable:any):Interceptors
    {
        this.calls.beforeRequest = callable;

        return this;
    }
}
