import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { EventsService } from './events.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  private role:string = '';

  public MERCHANT:string = 'ME';
  public TENNANT:string = 'TE';
  public CUSTOMER:string = 'CU';
  public SUPERADMIN:string = 'SU';

  public COMPANY:Array<string> = [ this.MERCHANT, this.TENNANT ];

  constructor(
    public storageService: StorageService,
    public eventsService: EventsService,
    public router: Router,
    public toastController: ToastController,
    public translate: TranslateService
  ) {
    this.updateData();
    this.registerListeners();
  }

  private updateData() 
  {
      if ( this.storageService ) {
          const user = this.storageService.get('user');
          if ( user ) {
              this.role = user.RoleType;
          }
      }
  }

  private registerListeners()
  {
    if ( this.eventsService ) {
        this.eventsService.subscribe('user:signin', (e:any) => this.role = e.RoleType);
        this.eventsService.subscribe('user:signout', () => this.role = '');
    }
  }

  public authorize(needed:Array<any>)
  {
    if ( needed.indexOf(this.role) < 0 ) {

      this.toastController.create({
        message: this.translate.instant('access_denied_to_resource'),
        color: 'danger',
        duration: 5000
      }).then(s => s.present());

      this.router.navigateByUrl('/')
    }
  }

  isMerchant()
  {
      return this.role == 'ME';
  }

  isTennant()
  {
      return this.role == 'TE';
  }

  isSuperAdmin()
  {
      return this.role == 'SU';
  }

  isCompany()
  {
      return this.isMerchant() || this.isTennant();
  }

  isCustomer()
  {
    return this.role == 'CU';
  }

  public canEditCards()
  {
    return this.isCompany() || this.isSuperAdmin();
  }

  public canRevertTransaction()
  {
    return this.isCompany() || this.isSuperAdmin();
  }
}
